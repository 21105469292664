import { FirebaseFirestore, FieldPath } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import "firebase/compat/firestore";
import { getAnalytics, isSupported } from "firebase/analytics";
import "firebase/compat/functions";
import "firebase/compat/storage";
import prd from "~/configs/firebase-prd";
import stg from "~/configs/firebase-stg";
import dev from "~/configs/firebase-dev";

if (!firebase.apps.length) {
  if (process.env.STAGE === "PRD") {
    firebase.initializeApp(prd);
  } else if (process.env.STAGE === "STG") {
    // TODO: Set up staging environment
    // firebase.initializeApp(stg);
    firebase.initializeApp(dev);
  } else if (process.env.STAGE === "DEV") {
    firebase.initializeApp(dev);
  } else {
    // when running tests
    firebase.initializeApp(dev);
  }
}

export const signInOptions = [EmailAuthProvider.PROVIDER_ID];
export const auth = getAuth();
export const db: FirebaseFirestore = firebase.firestore();
export const functions: firebase.functions.Functions = firebase
  .app()
  .functions("asia-northeast1");
export const documentId: FieldPath = firebase.firestore.FieldPath.documentId();
export const analytics = getAnalytics()
export const storage = firebase.storage();
export const currentUTCTime = firebase.firestore.Timestamp.now()

// Use if using firestore emulator
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }
